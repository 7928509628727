import { NgIf } from '@angular/common';
import { Component, DestroyRef, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoService } from '@jsverse/transloco';
import { FormFieldLegacyAppearanceStandardDirective } from '@shared/ui/directives';
import { ucFirst } from '@shared/util/code';
import { IColumn } from '../Interfaces/IColumn';
import { IColumnBase } from '../Interfaces/IColumnBase';
import { FilterColumnService, IColumnFilter } from '../column-filter/filter-column.service';
import { IColumnDefinition } from '../mat-cell-content/column-extender';
import { FilterService } from '../services/filter.service';
import { Selectable } from '../table/dynamic-table.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-header-cell-content',
  templateUrl: './mat-header-cell-content.component.html',
  styleUrls: ['./mat-header-cell-content.component.scss'],
  imports: [
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    FormFieldLegacyAppearanceStandardDirective,
    MatIconModule,
    MatSortModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
})
export class MatHeaderCellContentComponent<TRowData, TRowVirtual> implements OnInit {
  readonly ucfirst = ucFirst;
  @HostBinding('class.mat-header-cell-content') class = true;
  @HostBinding('class.mat-cell-icon') classIcon = false;
  @HostBinding('class.mat-cell-checkbox') classCheckbox = false;
  @Output() selectionChanged = new EventEmitter();
  @Output() applyFilter = new EventEmitter();
  @Input() disableSort!: boolean;
  @Input() disableFilterPopover!: boolean;
  @Input() column!: IColumnDefinition<TRowData, TRowVirtual>;
  @Input() columnDefinitions!: IColumnDefinition<TRowData, TRowVirtual>[];
  @Input() dataSource!: MatTableDataSource<TRowData>;
  @Input() useStorage!: boolean;
  @Input() showClearFilter!: boolean;
  columnSelectorValue = false;

  constructor(
    private elementRef: ElementRef,
    public translocoService: TranslocoService,
    private destroyRef: DestroyRef,
    private filterColumnService: FilterColumnService<TRowData, TRowVirtual>,
    private filterService: FilterService<TRowData, TRowVirtual>,
  ) {}

  ngOnInit(): void {
    this.classIcon = this.column.type === 'icon';
    this.classCheckbox = this.column.type === 'checkbox';
  }

  isClear(): boolean {
    return this.column.name === 'clear';
  }

  isSelector(): boolean {
    return !this.column.hasHeaderSelector ? false : this.column.hasHeaderSelector;
  }

  hasSort(): boolean {
    return this.disableSort ? false : this.column.sortFunc !== null && this.column.name !== 'clear';
  }

  hasFilter(): boolean {
    return this.column.filterFunc !== null && this.column.name !== 'clear' && this.column.type !== 'checkbox';
  }

  descriptionOnly(): string {
    return !this.hasSort() ? this.column.text : '';
  }

  getTooltip(column: IColumn<TRowData, TRowVirtual>): string {
    return column.toolTip && typeof column.toolTip === 'string' ? column.toolTip : '';
  }

  onValueChanged(event: KeyboardEvent) {
    const search = (event.target as unknown as { value: string }).value;
    this.updateFilter(this.toFilter(search));
  }

  onClearFilter() {
    this.filterService.clearFilter(this.columnDefinitions, this.useStorage);
    this.applyFilter.emit();
  }

  onExactFilterClick(): void {
    const data = this.dataSource.data;
    const filterFunctions = this.filterService.getFilterInfos(this.columnDefinitions).filter((x) => x.columnName !== this.column.name);
    const filteredData = data.filter(this.filterService.getFilterPredicate(filterFunctions));

    this.filterColumnService
      .showFilterMenu(this.elementRef.nativeElement, filteredData, this.column)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateFilter(this.column.searchValues));
  }

  onColumnSelectorChange(checked: boolean): void {
    const headerSelectorFunc = this.columnDefinitions.find((x) => x.headerSelectorFunc)?.headerSelectorFunc;
    if (headerSelectorFunc) {
      headerSelectorFunc(this.dataSource.filteredData, checked);
    } else {
      (this.dataSource.filteredData as Selectable<TRowData>[]).forEach((e) => (e.selector = checked));
    }
    this.selectionChanged.emit();
  }

  private updateFilter(value: IColumnFilter | undefined): void {
    const column = this.column;
    this.filterService.updateFilter(this.columnDefinitions, column, value, this.useStorage);
    this.applyFilter.emit();
  }

  private toFilter(value: string): IColumnFilter | undefined {
    if (!value) {
      return undefined;
    }
    const wildcards = value.split('|');
    const wildcardsLowerCase = value.toLowerCase().split('|');
    return { wildcards: wildcards, exact: new Set(), wildcardsLowerCase: wildcardsLowerCase };
  }
}

export interface IColumSelectorChanged<TRowData> {
  column: IColumnBase<TRowData>;
  value: boolean;
}
