import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { collapseAnimation } from 'angular-animations';
import { ContentDirective } from './content.component';
import { TableExpandableComponent } from './table-expandable.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'expanded-content-wrapper',
  imports: [CommonModule, ContentDirective],
  animations: [collapseAnimation()],
  template: `<div [@collapse]="row !== component.selectedRow$.value"><ng-container [expandedContent]="component" [row]="row"></ng-container></div>`,
})
export class ContentWrapperComponent<TRow> {
  @HostBinding('class.w-100') class = true;
  @Input() row!: TRow;
  @Input() component!: TableExpandableComponent<TRow>;
}
