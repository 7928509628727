import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IColumn } from '../Interfaces/IColumn';
import { IColumnDefinition } from '../mat-cell-content/column-extender';
import {} from '../mat-cell-content/mat-cell-host.directive';
import { PopOverService } from '../popover/popover.service';
import { IDynamicTableEvents } from '../table/table-events.component';
import { NavigationService } from './navigation.service';
import { IPopupEdit, PopOverEditComponent } from './popover-edit.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'inline-edit-button',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: 'inline-edit-button.component.html',
  imports: [MatIconModule, MatButtonModule],
})
export class InlineEditButtonComponent<TRowData, TRowVirtual> implements OnInit, OnDestroy, OnDestroy {
  @Input() host!: IDynamicTableEvents<TRowData, TRowVirtual>;
  @Input() row!: TRowData;
  @Input() index!: number;
  @Input() column!: IColumnDefinition<TRowData, TRowVirtual>;
  private matCell!: HTMLElement;
  constructor(
    private popOverService: PopOverService<IPopupEdit<TRowData, TRowVirtual>>,
    private destroyRef: DestroyRef,
    private navigation: NavigationService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.matCell = this.elementRef.nativeElement;
    this.navigation.addCol(this.row, this.column.name, this as InlineEditButtonComponent<unknown, unknown>);
  }

  ngOnDestroy(): void {
    this.navigation.removeCol(this.row, this.column.name);
    this.popOverService.close();
  }

  getColor(row: TRowData, column: IColumn<TRowData, TRowVirtual>): string {
    return !column.color ? '' : typeof column.color === 'string' ? column.color : column.color(row);
  }

  onInlineEditClick(): void {
    const row = this.row;
    const column = this.column;
    const origin = this.matCell;
    this.popOverService
      .open(origin, PopOverEditComponent, { row, column }, origin.clientWidth)
      .valueChanged$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (event === 'backdropClick') {
          return;
        }
        this.navigation.clickNext(this.row, this.column.name, event === 'next' ? 1 : -1);
      });
  }
}
export type ItemData = {
  row: unknown;
  cols: Record<string, InlineEditButtonComponent<unknown, unknown>>;
};
