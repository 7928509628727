import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ucFirst } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { IColumnFilter, IColumnFilterOptions, IListBoxType } from '../column-filter/filter-column.service';
import { FilterPopupRef } from '../column-filter/filter-popup-ref';

const itemSize = 56;
@Component({
  selector: 'lsu-dynamic-table-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss'],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatListModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    TranslocoModule,
    NgStringPipesModule,
  ],
})
export class ColumnFilterComponent implements AfterViewInit, AfterViewChecked {
  readonly ucfirst = ucFirst;
  listBoxHeight = 0;

  itemSize = itemSize;
  data: IColumnFilterOptions<unknown>;
  options: IListBoxType[] | null = null;
  @Input() selectPlaceholder = ucFirst(this.transLocoService.translate('common._actions.search'));
  private searchValues: IColumnFilter;
  @ViewChild('panel', { read: ElementRef, static: true }) private panelRef: ElementRef = {} as ElementRef;
  @ViewChild('searchInput', { read: ElementRef, static: true }) private inputRef: ElementRef = {} as ElementRef;
  private optionLength = itemSize;
  constructor(
    private filterPopupRef: FilterPopupRef<IColumnFilterOptions<unknown>>,
    public transLocoService: TranslocoService,
  ) {
    const value = (this.data = this.filterPopupRef.data);
    value.options
      .filter((x) => x.value === '')
      .forEach((x) => (x.display = '&lt;' + ucFirst(this.transLocoService.translate('common.empty')) + '&gt;'));
    this.options = value.options;
    this.searchValues = this.data.column.searchValues ?? { exact: new Set(), wildcards: [], wildcardsLowerCase: [] };
    this.optionLength = itemSize * this.data.options.length + 20; // add some margin for horizontal scrollbar

    this.chromeIssueCorrection();
  }

  get selectAllChecked(): boolean {
    if (!this.options) {
      return false;
    }
    const isSelected = this.options.filter((x) => this.searchValues.exact.has(x.value));
    return isSelected.length === this.options.length && isSelected.length > 0;
  }

  isSelected(o1: string | number | null | boolean): boolean {
    if (o1 === null) {
      return this.searchValues.exact.has('');
    }
    if (typeof o1 === 'number') {
      return this.searchValues.exact.has(o1);
    }
    o1 = o1 + '';
    return this.searchValues.exact.has(o1);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.inputRef.nativeElement.focus());
  }

  ngAfterViewChecked(): void {
    const topOverlay = this.getOverlayTop(this.panelRef.nativeElement);
    let maxHeight = window.innerHeight - topOverlay - 230;
    maxHeight = Math.max(maxHeight, itemSize);
    if (maxHeight !== this.listBoxHeight) {
      this.listBoxHeight = maxHeight;
      this.setMaxHeight();
    }
  }

  onFilterItem(value: string): void {
    value = value.toLowerCase();
    const id = 'value';
    const func = this.data.filterFunc; //use the same filterFunc specified in the columnDefinition for wildcard search
    const search = { exact: new Set<string>(), wildcards: [], wildcardsLowerCase: [value] };
    this.options = this.data.options.filter((row) =>
      func(
        () => row[id],
        row,
        search,
        () => row.display,
      ),
    );
  }

  selectionChanged(value: string | number | boolean): void {
    if (this.searchValues.exact.has(value)) {
      this.searchValues.exact.delete(value);
    } else {
      this.searchValues.exact.add(value);
    }
    this.data.column.searchValues = this.searchValues;
    this.filterPopupRef.valueChanged();
  }

  toggleSelectAll(val: { checked: boolean }): void {
    const s: IColumnFilter = this.searchValues;
    if (val.checked && this.options) {
      this.options.forEach((x) => s.exact.add(x.value));
    } else {
      this.options?.forEach((x) => s.exact.delete(x.value));
    }
    this.data.column.searchValues = {
      exact: s.exact,
      wildcards: s.wildcards,
      wildcardsLowerCase: s.wildcards.map((x) => x.toLowerCase()),
    };
    this.filterPopupRef.valueChanged();
  }

  onClearFilters(): void {
    this.data.column.searchValues = this.searchValues = { exact: new Set(), wildcards: [], wildcardsLowerCase: [] };
    this.filterPopupRef.close();
  }

  onClose(): void {
    this.filterPopupRef.close();
  }

  private getOverlayTop(htmlElement: HTMLElement | null): number {
    while (htmlElement && !htmlElement.style.top) {
      htmlElement = htmlElement.parentElement as HTMLElement;
    }
    return htmlElement ? +htmlElement.style.top.replace('px', '') : 0;
  }

  private setMaxHeight(): void {
    const htmlElement: HTMLElement = this.panelRef.nativeElement;
    htmlElement.style.height = `${this.optionLength > this.listBoxHeight ? this.listBoxHeight : this.optionLength}px`;
  }

  private chromeIssueCorrection() {
    const chromeMaxScrollLength = 22369618 - 2369618; // need some marge spacer div with + rendered items (unknown size) should be below 22369618
    if (itemSize * this.data.options.length > chromeMaxScrollLength) {
      this.itemSize = chromeMaxScrollLength / this.data.options.length;
    }
  }
}
