import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'lsu-dynamic-table-expandable',
  template: '<ng-template #tplRef><ng-content></ng-content></ng-template>',
  standalone: true,
})
// typescript can't find out the type of TRow. Typescript need at least some input parameter which specify TRow.
// Now the expand event is type any;
export class TableExpandableComponent<TRow> {
  @ViewChild('tplRef', { read: TemplateRef }) tplRef!: TemplateRef<unknown>;
  @Input() expandOnRowClick = true;
  @Output() expand = new EventEmitter<TRow>();
  selectedRow$ = new BehaviorSubject<TRow | undefined>(undefined);
  get row() {
    return this.selectedRow$.value;
  }

  onRowClick(row: TRow) {
    if (this.expandOnRowClick) {
      this.toggle(row);
    }
  }

  collapse = () => this.toggle(this.row!);
  toggle(row: TRow): void {
    if (this.selectedRow$.value === row) {
      this.selectedRow$.next(undefined);
    } else {
      this.selectedRow$.next(row);
      this.expand.emit(row);
    }
  }
}
