import { Directive, Input, OnInit } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';
import { ToolTipFunc } from '../Interfaces/IColumnBase';
import { IColumnDefinition } from './column-extender';

@Directive({
  selector: '[lsuDynamicTableTooltip]',
  standalone: true,
})
export class TooltipDirective<TRowData, TRowVirtual> extends MatTooltip implements OnInit {
  @Input('lsuDynamicTableTooltip') row!: TRowData;
  @Input() column!: IColumnDefinition<TRowData, TRowVirtual>;
  override get message() {
    return this.column._tooltipFunc(this.row);
  }

  override get position() {
    return this.column._tooltipPositionFunc(this.row);
  }

  ngOnInit() {
    this.showDelay = 700;
  }
}
export interface TooltipOptions {
  position?: TooltipPosition;
  showDelay?: number;
}
export function getTooltipFunc<T>(toolTip?: ToolTipFunc<T> | string) {
  if (!toolTip || typeof toolTip === 'string') {
    return () => toolTip ?? '';
  }

  return (row: T) => {
    const value = toolTip(row);
    return typeof value === 'string' ? value : value.msg;
  };
}
export function getTooltipPositionFunc<T>(toolTip?: ToolTipFunc<T> | string) {
  if (!toolTip || typeof toolTip === 'string') {
    return () => 'above' as TooltipPosition;
  }

  return (row: T) => {
    const value = toolTip(row);
    return typeof value === 'string' ? ('above' as TooltipPosition) : value.position;
  };
}
