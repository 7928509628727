import { DestroyRef, Directive, Input, OnChanges, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnName } from '../Interfaces/IColumn';
import { IColumnDefinition } from '../mat-cell-content/column-extender';
import { getTableHash } from '../services/filter.service';
import { SortService } from '../services/sort.service';

@Directive({
  selector: '[lsuDynamicTableSort]',
  standalone: true,
})
export class SortDirective<TRowData, TRowVirtual> implements OnChanges, OnInit {
  @Input() useStorage!: boolean;
  @Input('lsuDynamicTableSort') matSort!: MatSort;
  @Input() dataSource!: MatTableDataSource<TRowData>;
  @Input() columnDefinitions!: IColumnDefinition<TRowData, TRowVirtual>[];
  @Input() columnId!: ColumnName<TRowData & TRowVirtual>;
  @Input() order!: SortDirection;
  private hash!: number;

  constructor(
    private sortService: SortService<TRowData, TRowVirtual>,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    if (this.useStorage) {
      this.matSort.sortChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((sort: Sort) => this.sortService.setSort(this.hash, sort));
    }
  }

  ngOnChanges() {
    const sortFunctions = this.sortService.initSort(this.columnDefinitions, this.matSort, this.columnId, this.order);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    this.dataSource.sortingDataAccessor = (data: TRowData, columnName: string) => sortFunctions[columnName] && sortFunctions[columnName](data);
    this.dataSource.sort = this.matSort;
    this.hash = getTableHash(this.columnDefinitions);
  }
}
